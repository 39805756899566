import React from "react";
import { ContactForm } from "../components/ContactForm";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

const ContactPage = () => {
  return (

<html lang='en'>

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Contact | Code BGP</title>
  <meta http-equiv="refresh" content="0; URL=https://www.thousandeyes.com/blog/cisco-announces-acquisition-of-codebgp" />
  {/* <script>
    async function subscribe(id) {
      const email = document.querySelector('#email').value;
      const first_name = document.querySelector('#first_name').value;
      const last_name = document.querySelector('#last_name').value;
      const message = document.querySelector('#message').value;
      const subject = document.querySelector('#subject').value;
      const organization = document.querySelector('#organization').value;

      document.querySelector('#emailform').reset();

      const body = {
        email, first_name, last_name, message, subject, organization
      };

      const url = "/contact-api";

      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        mode: 'no-cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json'
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(body) // body data type must match "Content-Type" header
      });

      const text = await response.json();

      document.querySelector(`#notification${id}`).style.visibility = 'visible';
      if (!text.status) {
        // if ( document.getElementById(`status${id}`).classList.contains('bg-amber-500') )
        document.querySelector(`#status${id}`).innerHTML = 'Message failed';
        document.querySelector(`#message${id}`).innerHTML = 'The message was not sent.';
        document.querySelector(`#icon${id}`).innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              `;
      } else {
        document.querySelector(`#status${id}`).innerHTML = 'Message sent';
        document.querySelector(`#message${id}`).innerHTML = 'The message was sent!';
        
              document.querySelector(`#icon${id}`).innerHTML = `
              <svg className="w-6 h-6 text-emerald-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
              `;
      }
    }
  </script> */}
</head>

<body>
</body>

</html>

  )
}

export default ContactPage
